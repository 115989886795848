import { render, staticRenderFns } from "./MaterialWizard.vue?vue&type=template&id=74f9325d&"
import script from "./MaterialWizard.vue?vue&type=script&lang=js&"
export * from "./MaterialWizard.vue?vue&type=script&lang=js&"
import style0 from "./MaterialWizard.vue?vue&type=style&index=0&id=74f9325d&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports